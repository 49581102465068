<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            .flex.items-center.gap-4(v-if="status_compare_target_detail == true")
                h2.mr-4.text-xl Detail {{compare_target_detail.task_name?compare_target_detail.task_name:''}}
                .flex.items-center.gap-4
                    img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="compare_target_detail.first_target.photos && compare_target_detail.first_target.photos[0] ? compare_target_detail.first_target.photos[0].file : getDefaultImage" @error="getDefaultImage")
                    .flex.flex-col
                        span {{compare_target_detail.first_target.name}}
                        span {{compare_target_detail.first_target_msisdn }} - {{ getProvider(compare_target_detail.first_target_msisdn) }}
                    
                .flex.items-center.gap-4
                    img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="compare_target_detail.second_target.photos && compare_target_detail.second_target.photos[0] ? compare_target_detail.second_target.photos[0].file : getDefaultImage" @error="getDefaultImage")
                    .flex.flex-col
                        span {{compare_target_detail.second_target.name}}
                        span {{compare_target_detail.second_target_msisdn }} - {{ getProvider(compare_target_detail.second_target_msisdn) }}


            .header-cta
                .flex.items-center
                    .block
                        router-link.btn-default(to="/compare-target-loc")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back

        .card__body.p-4.pt-0
            .flex.items-center.mb-2.justify-between
                .flex.items-center.flex-grow.gap-3
                    .field
                        .relative.field-style.w-full
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker" 
                                v-model="dateRange" 
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                :date-format="dateFormat" 
                                opens="right"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                //- timePicker=true
                                //- autoApply=true
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .field-icon
                                IconSet(type="calendar")

                    button.btn-primary-o(@click="toggleAdvanceFilter()") Advance Datetime Filter

                    .field(class="lg:mb-0 lg:w-48 mr-0 w-full mb-4")
                        //- select.field-style#filter-record-size(@change="orderChange($event)" style="min-width:80px")
                        select.field-style#filter-record-size(style="min-width:160px" v-model="order_by")
                            option(v-for="(s,index) in orderBy" :key="index" :value="s.value") {{ s.label }}
                        .field-icon
                            IconSet(type="chevron-down")

                    //- .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4" v-if="order_by == ''")
                        select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(type="chevron-down")

                    button.btn-primary(
                        :disabled="isLoading"
                        :class="{'btn-disabled':isLoading}" 
                        @click="getData()"
                    ) Filter

                .block(v-if="!isLoading && (location_es_list.list && location_es_list.list.data && location_es_list.list.data.length > 0)")
                    .flex.flex-wrap.items-center.justify-between
                        .field.mr-3.relative
                        JsonCSV(
                            :data="csvData"
                            :name="csvName"
                            class="btn btn-primary"
                        )
                            | Download CSV

            //-===== summary filter
            .flex.flex-wrap.items-center.p-4.border.border-black.mb-2.gap-4.bg-black
                .flex.flex-wrap.gap-2.items-center
                    p.text-sm Filter date 
                    template(v-if="Object.keys(selectedTimeByDate).length > 0")
                        span(v-for="(value, key) in selectedTimeByDate" :key="key").bg-gray-800.text-xs.p-1.block {{dateFormatGlob(key,'DD MMMM YYYY')}} {{toString(value,null)}}
                    span(v-else).bg-gray-800.text-xs.p-1.block {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
                    //- span.bg-gray-800.text-xs.p-1.block 1 Jan 2022 00:00 - 24:00
                    //- span.bg-gray-800.text-xs.p-1.block 6 Jan 2022 00:00 - 24:00
                    //- span.bg-gray-800.text-xs.p-1.block 7 Jan 2022 10:00 - 11:00, 11:00 - 12:00, 12:00 - 13:00, 13:00 - 14:00
            
            .card.card--tab.col-span-2
                .card--tab__body
                    .box-tabs
                        vue-tabs(@tab-change="handleTabChange")
                            v-tab(title="Target Locations")
                                TargetLocation

                            v-tab(title="Targets Possibility Meet")
                                TargetPossibilityMeet

    .grid.grid-cols-3.gap-4.p-4
        .card.card--tab.col-span-2
            .card--tab__header
                h2.text-xl Most Visited Location
            .card--tab__body
                .box-tabs
                    vue-tabs
                        v-tab(title="Weekdays")
                            //- Weekdays(:chartData="location_es_list.statistic.most_visited_location.top_weekday")
                            Weekdays                       
                        v-tab(title="Weekend")
                            //- Weekend(:chartData="location_es_list.statistic.most_visited_location.top_weekend")
                            Weekend                        
                        v-tab(title="Rest Time")
                            //- RestTime(:chartData="location_es_list.statistic.most_visited_location.top_rest_time")
                            RestTime

                        v-tab(title="Working Hours")
                            //- WorkingHours(:chartData="location_es_list.statistic.most_visited_location.top_working_hours")
                            WorkingHours
        
        .card
            .card__header
                h2.text-xl Most Found Location
            .card__body
                //- Location(:chartData="location_es_list.statistic.most_found_location")
                Location
    //-=== advance filter template ====-//
    .section-advance-filter(v-if="openAdvanceFilter")
        .section-advance-filter__header
            .flex-grow.flex.flex-col.gap-2
                .flex.items-center.gap-2
                    .flex.text-sm Showing Data from {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
                    button.btn-primary-o.btn-small(@click="toggleTimeFilter('all')") Filter Time

                .text-xs Please select specific date time bellow:
                .flex.items-center.gap-2
                    p.text-xs Selected date
                    .flex.flex-wrap.gap-2.items-center.font-bold
                        template(v-if="Object.keys(selectedTimeByDate).length > 0")
                            span(v-for="(value, key) in selectedTimeByDate" :key="key").bg-gray-800.text-xs.p-1.block {{dateFormatGlob(key,'DD MMMM YYYY')}} {{toString(value,null)}}
                        span(v-else).bg-gray-800.text-xs.p-1.block {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
                
            
            .flex.items-center.gap-2
                button.btn-default(@click="toggleAdvanceFilter('reset')") Reset
                button.btn-primary(@click="toggleAdvanceFilter()") Save Filter
        
        .section-advance-filter__body
            .box-month(v-for="month,idx in months" :key="idx")
                .block.mb-2 {{month.name_long}} {{month.year}}
                .grid.grid-cols-10.gap-2
                    button.btn-item(
                        v-for="i in month.days" :key="i" 
                        v-if="i>=month.start_day && i <= month.end_day"
                        @click="toggleTimeFilter(month.year+'-'+month.month+'-'+String(i).padStart(2, '0'))" 
                        :class="checkDateStyle(month.year+'-'+month.month+'-'+String(i).padStart(2, '0'))"
                    )
                        //- :class="{'btn-item--selected': btnSelectedMonth.includes(i)}" 
                        span {{i}} {{month.name_short}}
                        span.text-xs {{ getTimeDesc(month.year+'-'+month.month+'-'+String(i).padStart(2, '0')) }}
                    
        //- select date time
        .modal-date-time(v-if="filterTime")
            .section-date-time
                .section-date-time__header
                    h2.text-white.text-xl {{ filterTimeBy == 'all'? (dateFormatGlob(from_date,'DD MMMM YYYY')+' - '+ dateFormatGlob(to_date,'DD MMMM YYYY')): dateFormatGlob(filterTimeBy,'DD MMMM YYYY')}}
                    button.btn-icon-40(@click="toggleTimeFilter()")
                        IconSet(type="close")
                .section-date-time__body
                    button.btn-item(
                        v-for="time in timeOpt"
                        @click="selectTime(time.value)"
                        :class="checkTimeStyle(time.value)"
                    ) {{time.label}}
                .section-date-time__footer
                    button.btn-primary-o(@click="selectTime('all')") Reset to 00:00 - 24:00
                    button.btn-primary(@click="toggleTimeFilter('apply')") Apply

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import { env } from '@/config/env';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Spinner from '@/components/Spinner.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { gmapApi } from 'vue2-google-maps';
// import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import MeasureTool from 'measuretool-googlemaps-v3';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import JsonCSV from 'vue-json-csv'

// openstreetmap
import {LMap, LTileLayer, LMarker, LPopup, LPolyline, LControl} from 'vue2-leaflet';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = ''
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import LeafletHeatmap from '@/components/LeafletHeatmap.vue';

import { VueTabs,VTab } from 'vue-nav-tabs'
import Weekdays from './top-info/Weekdays.vue'
import Weekend from './top-info/Weekend.vue'
import RestTime from './top-info/RestTime.vue'
import WorkingHours from './top-info/WorkingHours.vue'
import Location from './top-info/Location.vue'
// import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';

import TargetLocation from './TargetMaps/TargetLocation.vue';
import TargetPossibilityMeet from './TargetMaps/TargetPossibilityMeet.vue';

export default {
    name: 'pageCompareTargetDetail',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        Loading,
        NoDataAvailable,
        VueAdsPagination,
        VueAdsPageButton,
        JsonCSV,
        // GmapCluster,
        MeasureTool,
        // OverlappingMarkerSpiderfier,
        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        LeafletHeatmap,
        LPolyline,
        LControl,
        LControlPolylineMeasure,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        'l-polyline-decorator': Vue2LeafletPolylineDecorator,
        VueTabs,
        VTab,
        Weekdays,
        Weekend,
        RestTime,
        WorkingHours,
        Location,
        TargetLocation,
        TargetPossibilityMeet
    },

    data() {
        // let patterns = [
        //     { offset: 12, repeat: 25, symbol: L.Symbol.arrowHead({pixelSize: 10, pathOptions: {color: this.polyline && this.polyline.color?this.polyline.color:'#000', weight: 2}}) },
        //     { offset: 0, repeat: 25, symbol: L.Symbol.arrowHead({pixelSize: 0}) }
        // ]
        return {
            // patterns,
            landscapeMap: false,
            mapFullScreen: false,
            openAdvanceFilter: false,
            filterTime: false,

            showFilter: false,
            // columnsTable: ['target','label','msisdn','lac','ci','rat','datetime','maps'],
            columnsTable: ['target','label','lac','ci','rat','datetime','maps'],
            optionsTable: {
                headings: {
                    'target': 'Target',
                    'label': '',
                    // 'msisdn': 'MSISDN',
                    'lac': 'LAC',
                    'ci': 'CI',
                    'rat': 'RAT',
                    'datetime': 'Datetime',
                    'maps': '',
                },
                filterable: false,
                sortable: [],
                // perPage: 10,
                pagination: { 
                    chunk: 5 
                    // dropdown: false 
                },
                // perPageValues: [10,25,50,100],
                skin: 'table table-overflow',
                // skin: 'table-static w-full p-4',
                sortIcon: { 
                    base:'iconfont',
                    is:'iconsort',
                    up: 'iconup',
                    down: 'icondown'
                },
                columnsClasses: {
                    'datetime': 'font-mini',
                },
                // orderBy:{column:false}
            },
            tableThead: [
                {
                    name: 'Target',
                },
                {
                    name: '',
                },
                {
                    name: 'MSISDN',
                },
                {
                    name: 'LAC',
                },
                {
                    name: 'CI',
                },
                // {
                //     name: 'BTS',
                // },
                {
                    name: 'RAT',
                },
                {
                    name: 'Datetime',
                },
                // {
                    // name: 'Requested At',
                // },
                {
                    name: '',
                    action: true,
                },
            ],
            
            optFilterTarget: [],
            filterTarget: '',
            paramMsisdn: [],
            total_data: 0,
            items_per_page: 10000,

            size: [10, 25, 50, 100],

            order_by:'',
            orderBy: [
                {
                    label: 'All Data', 
                    value: ''
                },
                {
                    label: 'Same Area Code', 
                    value: 'area_code'
                },
                {
                    label: 'Same Cell', 
                    value: 'cell'
                },
            ],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [
                    moment().set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).toDate()
                ],
                'Yesterday': [
                    moment().subtract(1, 'days').set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).subtract(1, 'days').toDate()
                ],
                'Last 7 days': [
                    moment().subtract(6, 'days').set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).toDate()
                ],
                'Last week': [
                    moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                    moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                ],
            },
            months: [],
            btnSelectedMonth: [],
            
            filterTimeBy: '',
            tmpSelectedTime:[],
            selectedTimeByDate:{},
            selectedTimeAll:[],
            timeOpt:[
                {
                    label:'00:00 - 01:00',
                    value:'00:00-01:00'
                },
                {
                    label:'01:00 - 02:00',
                    value:'01:00-02:00'
                },
                {
                    label:'02:00 - 03:00',
                    value:'02:00-03:00'
                },
                {
                    label:'03:00 - 04:00',
                    value:'03:00-04:00'
                },
                {
                    label:'04:00 - 05:00',
                    value:'04:00-05:00'
                },
                {
                    label:'05:00 - 06:00',
                    value:'05:00-06:00'
                },
                {
                    label:'06:00 - 07:00',
                    value:'06:00-07:00'
                },
                {
                    label:'07:00 - 08:00',
                    value:'07:00-08:00'
                },
                {
                    label:'08:00 - 09:00',
                    value:'08:00-09:00'
                },
                {
                    label:'09:00 - 10:00',
                    value:'09:00-10:00'
                },
                {
                    label:'10:00 - 11:00',
                    value:'10:00-11:00'
                },
                {
                    label:'11:00 - 12:00',
                    value:'11:00-12:00'
                },
                {
                    label:'12:00 - 13:00',
                    value:'12:00-13:00'
                },
                {
                    label:'13:00 - 14:00',
                    value:'13:00-14:00'
                },
                {
                    label:'14:00 - 15:00',
                    value:'14:00-15:00'
                },
                {
                    label:'15:00 - 16:00',
                    value:'15:00-16:00'
                },
                {
                    label:'16:00 - 17:00',
                    value:'16:00-17:00'
                },
                {
                    label:'17:00 - 18:00',
                    value:'17:00-18:00'
                },
                {
                    label:'18:00 - 19:00',
                    value:'18:00-19:00'
                },
                {
                    label:'19:00 - 20:00',
                    value:'19:00-20:00'
                },
                {
                    label:'20:00 - 21:00',
                    value:'20:00-21:00'
                },
                {
                    label:'21:00 - 22:00',
                    value:'21:00-22:00'
                },
                {
                    label:'22:00 - 23:00',
                    value:'22:00-23:00'
                },
                {
                    label:'23:00 - 24:00',
                    value:'23:00-23:59'
                },
            ],

            csvName: 'compare_target_loc_list'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],
            
            // map history
            mapVendor: this.$store.getters['config/getMapVendor'],
            // mapVendor: 'gmap',
            infoWindowsHistory: [],
            mapHistoryLoaded: false,
            markers_history: [],
            mapsLatLngHistory: [], // map history
            mapsLatLngTarget: {}, // map history
            // mapsLatLngTarget: [], // map history
            polyline_history: null, // map history
            measureTool: null, // map history
            measureToolStatus: false, // map history
            options: {
                zoomControlOptions: {
                    position: 1,
                    // 1 = TOP_LEFT
                    // 2 = TOP_CENTER
                    // 3 = TOP_RIGHT
                },
                fullscreenControl : false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            locations_cluster: [],
            polyline: {
                latlngs:[],
                color:'red'
            },
            // gmap
            infoWindows: [],
            center: {},
            markers: [],
            // places: [],
            heatmap: null,
            mapsLatLng: [],

            idOSM: 'compare_target_loc',
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],
            cluster_opt: {
                // spiderfyOnMaxZoom: true,
                // showCoverageOnHover: true,
                // zoomToBoundsOnClick: true
            },
            bounds: [],

            // icon_colors: [],
            latLngHeat: [],
            maxHeat: 1.0,
            radHeat: 30,

            // showMarkerOsm: true,
            showPolylineOsm: false,
            showHeatmapOsm: false,

            currentClickMarker: null,
            spideredMarker: [],

            gInfoWIndow: null,
        }
    },
    computed: {
        ...mapState({
            compare_target_detail: (state) => state.compareTarget.detail,
            status_compare_target_detail: (state) => state.compareTarget.status_detail,
            location_es_list: (state) => state.locationEs.list,
            status_location_es_list: (state) => state.locationEs.status_list,
        }),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        google: gmapApi,
        classObject() {
            return true    
        },
        patterns (){ 
            return [
                { offset: 12, repeat: 25, symbol: L.Symbol.arrowHead({pixelSize: 10, pathOptions: {color: this.polyline && this.polyline.color?this.polyline.color:'#000', weight: 2}}) },
                { offset: 0, repeat: 25, symbol: L.Symbol.arrowHead({pixelSize: 0}) }
            ]
        },
        isLoading(){
            if(this.status_location_es_list.status === 'loading' || this.status_compare_target_detail === 'loading') {
                return true
            }
            return false
        },
    },
    methods: {
        animatePolyline(line) {
            let count = 0;

            window.setInterval(() => {
                count = (count + 1) % 200;

                const icons = line.get("icons");

                icons[0].offset = count / 2 + "%";
                line.set("icons", icons);
            }, 100);
        },
        showPopup(index, coor) {
            if (this.mapVendor == 'osm') {
                try {
                    // console.log('coor', coor)
                    const marker = this.$refs['marker_'+index][0].mapObject 
                    const map = this.$refs.mapRefOSM.mapObject
                    // console.log('map')
                    // console.log(map)
                    // if (this.bounds.length){
                    //     map.fitBounds(L.latLngBounds([this.bounds]));
                    // }
                    map.fitBounds(L.latLngBounds([[coor[1], coor[0]]]));
                    // console.log(marker)
                    marker.openPopup()
                    console.log('showPopUp marker')
                } catch (error) {
                    const marker = this.$refs['marker_'+index+"_cluster"][0].mapObject 
                    // console.log('marker in cluster')
                    // console.log(marker)
                    const cluster = this.$refs['marker_cluster_'+coor[1]+','+coor[0]]
                    // console.log('cluster')
                    // console.log(cluster)
                    for (let index = 0; index < cluster.length; index++) {
                        const elem = cluster[index].mapObject
                        // console.log('elem')
                        // console.log(elem)
                        elem.zoomToShowLayer(marker, function () {
                            marker.openPopup();
                        });
                        // marker.openPopup();
                        // console.log('showPopUp cluster')
                    }
                }
            } else {
                const google = this.google;  
                const map = this.$refs.mapRef
                const markerLatLng = this.markers_history[index].getPosition()
                // console.log(map)
                map.panTo(markerLatLng);
                // console.log('map.panTo')
                try {
                    const mapObj = map.$mapObject
                    // console.log('mapObj', mapObj)
                    const mapZoom = mapObj.zoom
                    // console.log('mapZoom', mapZoom)
                    if (mapZoom < 17) {                        
                        var bounds = new google.maps.LatLngBounds();
                        bounds.extend(markerLatLng)
                        // console.log('bounds.extend')
                        map.fitBounds(bounds); 
                        // console.log('map.fitBounds')
                    }
                    // console.log('sukses map.fitBounds(bounds)')
                } catch (error) {
                    console.log('=====error', error)
                    // console.log('error map.fitBounds(bounds)')
                }

                // google.maps.event.trigger(this.markers_history[index], 'click');
                // console.log('this.currentClickMarker:pre')
                this.currentClickMarker = this.markers_history[index]
                // console.log('this.currentClickMarker:post')
            }
        },
        getTargetName(msisdn) {
            var target = this.optFilterTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0].name
            } else {
                return '-'
            }
        },
        getTargetPhoto(msisdn) {
            var target = this.optFilterTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                try {
                    return target[0].photo[0].file
                } catch (error) {
                    // console.log(error)
                }
            }
            return '-'
        },
        getTargetLabel(msisdn) {
            var target = this.optFilterTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0].label
            } else {
                return 'white'
            }
        },
        getTargetData(msisdn) {
            var target = this.optFilterTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0]
            } else {
                return '-'
            }
        },
        setData(data) {
            // console.log('=====setData', data)
            // this.mapHistoryLoaded = false;
            this.optFilterTarget= []
            this.filterTarget= ''
            this.paramMsisdn= []
            // var targets = data.target
            let pushMsisdnTarget = true
            if (![null,''].includes(data.first_target_msisdn)) {
                this.paramMsisdn.push(data.first_target_msisdn)
                pushMsisdnTarget = false
            }
            var first = data.first_target
            for (var i = 0; i < first.clues.length; i++) {
                if (first.clues[i].name == 'msisdn') {
                    if (pushMsisdnTarget) {
                        this.paramMsisdn.push(first.clues[i].value)
                    }
                    var tmpTarget = {
                        'id': first.id,
                        'name': first.name,
                        'msisdn': first.clues[i].value,
                        'label': first.label,
                        'photo': first.photos,
                    }
                    this.optFilterTarget.push(tmpTarget)
                }
            }
            let pushMsisdnTarget2 = true
            if (![null,''].includes(data.second_target_msisdn)) {   
                this.paramMsisdn.push(data.second_target_msisdn)
                pushMsisdnTarget2 = false
            } 
            var second = data.second_target
            for (var j = 0; j < second.clues.length; j++) {
                if (second.clues[j].name == 'msisdn') {
                    if (pushMsisdnTarget2) {
                        this.paramMsisdn.push(second.clues[j].value)
                    }
                    var tmpTarget2 = {
                        'id': second.id,
                        'name': second.name,
                        'msisdn': second.clues[j].value,
                        'label': second.label,
                        'photo': second.photos,
                    }
                    this.optFilterTarget.push(tmpTarget2)
                }
            }
            
            try {
                if (data.from_date && data.to_date) {
                    this.dateRange.startDate = moment(data.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(data.to_date, 'YYYY-MM-DD').toDate();

                    this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                } else {
                    const default_date = this.$store.getters['config/getDefaultDate'](14);

                    this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD').toDate();

                    this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                }
                this.getMonthInfo()
            } catch (error) {
                console.log('=====error', error)
            }

            
            this.getData();
        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY:ssZ');
            return datetime;
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        getData(first = false) {
            if (first) {                
                EventEmit.$emit('showLoading', true);
                
                this.$store.dispatch('compareTarget/getDetail', [this.id])
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            } else {
                this.setEmptyMap();
                // this.mapHistoryLoaded = false;

                var msisdn = null
                if (this.filterTarget) {
                    msisdn = this.filterTarget
                } else {
                    msisdn = JSON.stringify(this.paramMsisdn)
                }
                let fromDate = this.from_date
                let toDate = this.to_date
                if (Object.keys(this.selectedTimeByDate).length > 0) {
                    let tmpFromDate = []
                    let tmpToDate = []
                    for (var key of Object.keys(this.selectedTimeByDate)) {
                        // console.log(key + " -> " + this.selectedTimeByDate[key])
                        this.selectedTimeByDate[key].forEach(element => {
                            const tmpTime = element.split("-")
                            // console.log(element + " -> " + element.split("-"))
                            tmpFromDate.push(key +' '+tmpTime[0])
                            tmpToDate.push(key +' '+tmpTime[1])
                        });
                    }
                    fromDate = JSON.stringify(tmpFromDate)
                    toDate = JSON.stringify(tmpToDate)
                } 
                // console.log('===fromDate:',fromDate)
                // console.log('===toDate:',toDate)
                this.$store.dispatch('locationEs/getList', ['list_advance', msisdn, this.items_per_page, (this.page + 1), fromDate, toDate, '', 'available_coordinate', '',this.order_by]);
            }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    target_name  : this.getTargetName(item.features[0].properties.msisdn),
                    msisdn       : item.features[0].properties.msisdn?item.features[0].properties.msisdn:'-',
                    lac          : item.features[0].properties.lac?item.features[0].properties.lac:(item.features[0].properties.tac?item.features[0].properties.tac:'-'),
                    ci           : item.features[0].properties.ci?item.features[0].properties.ci:'-',
                    rat          : item.features[0].properties.net?item.features[0].properties.net:(item.features[0].properties.rat?item.features[0].properties.rat:'-'),
                    // location_name: item.features[0].properties.alamat ? item.features[0].properties.alamat : '-',
                    // bts_id: item.features[0].properties.bts ? item.features[0].properties.bts : '-',
                    // state: item.features[0].properties.state ? item.features[0].properties.state : '-',
                    coordinates  : item.features[0].geometry.coordinates[0]?item.features[0].geometry.coordinates[1]+','+item.features[0].geometry.coordinates[0]:'-',
                    datetime     : item.features[0].properties.dtm?item.features[0].properties.dtm:'-',
                };
            })
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        async pickerUpdate(e) {
            // this.setEmptyMap();
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            this.getMonthInfo()
        },
        hideAllInfoWindows(history=false) {
            for (var i=0; i < this.infoWindowsHistory.length; i++) {
                this.infoWindowsHistory[i].close();
            }
        },
        createControlGmap(map) {
            const controlButton = document.createElement("button");

            // Set CSS for the control.
            controlButton.style.backgroundColor = "#fff";
            controlButton.style.border = "2px solid #fff";
            controlButton.style.borderRadius = "3px";
            controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
            controlButton.style.color = "rgb(25,25,25)";
            controlButton.style.cursor = "pointer";
            controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
            controlButton.style.fontSize = "14px";
            controlButton.style.lineHeight = "38px";
            controlButton.style.margin = "8px 8px 0";
            controlButton.style.padding = "0 14px";
            controlButton.style.textAlign = "center";
            controlButton.textContent = "Ruler";
            controlButton.title = "Click to show ruler";
            controlButton.type = "button";
            controlButton.setAttribute("id", "button_measure");
            // Setup the click event listeners: simply set the map to Chicago.
            // const chicago = { lat: 41.85, lng: -87.65 };
            controlButton.addEventListener("click", this.toggleMeasure);
            return controlButton;
        },
        setEmptyMap() {
            // map history
            this.mapHistoryLoaded = false;
            this.infoWindowsHistory = [];
            this.markers_history = [];
            this.mapsLatLngHistory = []; // map history
            this.mapsLatLngTarget = {}; // map history
            this.polyline_history = null; // map history

            // osm
            this.locations = [];
            this.locations_cluster = [];
            this.polyline = {
                latlngs:[],
                color:'red'
            };
        },

        toggleLandscape() {
            this.landscapeMap = !this.landscapeMap
        },
        toggleFullScreen() {
            this.mapFullScreen = !this.mapFullScreen
            const el = document.body

            if(this.mapFullScreen === true) {
                // console.log('body freezee')
                el.classList.add('body-freeze')
            }else {
                // console.log('body oveflow')
                el.classList.remove('body-freeze')
            }
        },
        toggleAdvanceFilter(action=null) {
            this.openAdvanceFilter = !this.openAdvanceFilter
            if (action =='reset') {
                this.selectedTimeAll = []
                this.selectedTimeByDate = {}
            }
            const el = document.body

            if(this.openAdvanceFilter === true) {
                // console.log('body freezee')
                el.classList.add('body-freeze')
            }else {
                // console.log('body oveflow')
                el.classList.remove('body-freeze')
            }
        },

        toggleTimeFilter(data) {
            this.filterTime = !this.filterTime
            if (this.filterTime) {
                this.filterTimeBy = data
                if (data == 'all') {
                    this.tmpSelectedTime=this.selectedTimeAll;
                } else {
                    try {
                        // console.log('===data:',data)
                        this.tmpSelectedTime = this.selectedTimeByDate[data] || []
                    } catch (error) {
                        console.log('=====error get ByDate', error)
                        this.tmpSelectedTime = []
                    }
                }
            } else {
                if (data=='apply') {
                    // console.log('=====toggleTimeFilter apply: this.filterTimeBy', this.filterTimeBy)
                    if (this.filterTimeBy == 'all') {
                        this.selectedTimeAll = this.tmpSelectedTime;
                        this.selectedTimeByDate = {};
                        if (this.tmpSelectedTime.length > 0) {
                            for (let i = 0; i < this.months.length; i++) {
                                // const element = this.months[i];
                                for (let j = this.months[i]['start_day']; j <= this.months[i]['end_day']; j++) {                   
                                    // example format '2022-12-31'
                                    const key =  String(this.months[i]['year'])+'-'+ String(this.months[i]['month'])+'-'+ String(j).padStart(2, '0') 
                                    this.selectedTimeByDate[key] = this.tmpSelectedTime;
                                }
                            }
                        }
                    } else {
                        if (this.tmpSelectedTime.length == 0) {
                            delete this.selectedTimeByDate[this.filterTimeBy]; 
                        } else {
                            this.selectedTimeByDate[this.filterTimeBy] = this.tmpSelectedTime;
                        }
                    }
                }
                this.filterTimeBy = '';
                this.tmpSelectedTime=[];
            }
        },
        selectTime(value){
            if (value == 'all') {                
                this.tmpSelectedTime=[];
                for (let index = 0; index < this.timeOpt.length; index++) {
                    const element = this.timeOpt[index].value;
                    this.tmpSelectedTime.push(element);
                }
            } else {
                if(!this.tmpSelectedTime.includes(value)){
                    this.tmpSelectedTime.push(value);
                }else{
                    this.tmpSelectedTime.splice(this.tmpSelectedTime.indexOf(value), 1);  //deleting
                }            
            }
        },
        checkTimeStyle(value){
            let btnDisabled = false
            let btnSelected = false
            try {                
                if(this.tmpSelectedTime.includes(value)){
                    btnSelected = true
                }
                if (this.filterTimeBy != 'all' && this.selectedTimeAll.length !=0) {
                    if(!this.selectedTimeAll.includes(value)){
                        btnDisabled = true
                    }
                }
            } catch (error) {
                // console.log('error checkTimeStyle')
                // console.log(error)
            }
            return {
                'btn-disabled': btnDisabled,  
                'btn-item--selected': btnSelected,  
            }
        },
        checkDateStyle(value){
            let btnSelected = false
            try {
                let check = this.selectedTimeByDate[value]
                if (check.length) {
                    btnSelected = true
                }
            } catch (error) {
                // console.log('error checkDateStyle')
                // console.log(error)
            }
            return { 
                'btn-item--selected': btnSelected,  
            }
        },
        getTimeDesc(value){
            let desc = '00:00 - 24:00'
            try {
                let check = this.selectedTimeByDate[value]
                if (check.length > 1) {
                    desc = 'Multiple times'
                } else {
                    desc = check.toString()
                }
            } catch (error) {
                // console.log('error checkDateStyle')
                // console.log(error)
            }
            return desc
        },
        getMonthInfo() {
            this.selectedTimeAll = []
            this.selectedTimeByDate = {}
            this.months = []
            var from = this.from_date.split(/-| /)
            var to = this.to_date.split(/-| /)

            var year = from[0]
            var month = from[1]
            while ((month <= parseInt(to[1]) && year == parseInt(to[0])) || (month != parseInt(to[1])+1 && year < parseInt(to[0]))) {
                const date = new Date();
                date.setMonth(month - 1);
                var getMonthLong = date.toLocaleString('default', { month: 'long' });
                var getMonthShort = date.toLocaleString('default', { month: 'short' });

                var getDays = new Date(year,month,0).getDate();

                this.months.push({
                    name_long: getMonthLong,
                    name_short: getMonthShort,
                    days: getDays,
                    year: year,
                    month: month,
                    start_day: (month == from[1] && year == from[0])? parseInt(from[2]):1,
                    end_day: (month == to[1] && year == to[0])? parseInt(to[2]):getDays,
                });
                
                if (month == 12){
                    month = 0
                    year++
                }
                month++
            }
            // this.showLoading(false);
        },
        toggleLines(e=null) {
            var self = this;
            switch(self.mapVendor) {
                case 'osm':
                    if (e.target.checked) {
                        self.showPolylineOsm = true;
                    } else {
                        self.showPolylineOsm = false;
                    }
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.polyline_history.setMap(self.polyline_history.getMap() ? null : map);
                        });
                    });
            }
        },
        toggleHeatmap(e=null) {
            var self = this;
            switch(self.mapVendor) {
                case 'osm':
                    if (e.target.checked) {
                        self.showHeatmapOsm = true;
                    } else {
                        self.showHeatmapOsm = false;
                    }
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.heatmap.setMap(self.heatmap.getMap() ? null : map);
                        });
                    });
            }
        },
        toggleMeasure() {
            // console.log('measureToolStatus before', this.measureToolStatus)
            this.measureToolStatus = !this.measureToolStatus
            // console.log('measureToolStatus after', this.measureToolStatus)
            var measureButton = document.getElementById('button_measure');
            if (this.measureToolStatus) {
                this.measureTool.start()
                measureButton.style.backgroundColor = "#43EA92";
            } else {
                measureButton.style.backgroundColor = "#fff";
                this.measureTool.end()
            }
        
            // var self = this;
            // switch(self.mapVendor) {
            //     default:
            //         self.$nextTick(() => {
            //             self.$refs.mapRef.$mapPromise.then((map) => {
            //                 self.heatmap.setMap(self.heatmap.getMap() ? null : map);
            //             });
            //         });
            // }
        },
        radiusHeatmap(e) {
            var self = this;
            switch(self.mapVendor) {
                case 'osm':
                    self.radHeat = parseInt(e.target.value);
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.heatmap.set('radius', parseInt(e.target.value));
                        });
                    });
            }
        },

        handleTabChange(tabIndex, newTab, oldTab) {
            if (this.status_location_es_list !== 'loading') {
                var tab = 'startMap'+newTab.title
                tab = tab.replace(/\s/g, '');
                // console.log(tab)
                EventEmit.$emit(tab)
            }    
            console.log("handleTabChange")
        },

    },
    watch: {
        // isLoading(newData){
        //     console.log('isLoadingnewData')
        //     console.log(newData)
        // },
        location_es_list(newData) {
            this.setEmptyMap();
            // this.mapHistoryLoaded = false;
            if (newData && newData.list) {                
                if (Object.keys(newData.list).length > 0) {
                    this.total_data = parseInt(newData.list.total_data);
                } else {
                    this.total_data = 0;
                }
                if (this.location_es_list.list && this.location_es_list.list.data && this.location_es_list.list.data.length > 0) {
                    this.getDataCsv(this.location_es_list.list.data)
                    // this.startMapHistory();        
                }
            }
        },
        total_data() {
            // this.pagination();
            if (isNaN(this.total_data)) {            
                this.total_data = 0
            }
        },
        items_per_page() {
            // this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
        compare_target_detail() {
            // if (this.compare_target_detail != undefined && this.compare_target_detail.target != undefined) {
            if (this.compare_target_detail != undefined && this.compare_target_detail.first_target != undefined && this.compare_target_detail.second_target != undefined) {
                if (this.getProvider(this.compare_target_detail.first_target_msisdn) == this.getProvider(this.compare_target_detail.second_target_msisdn)) {
                    this.orderBy = [
                        {
                            label: 'All Data', 
                            value: ''
                        },
                        {
                            label: 'Same Area Code', 
                            value: 'area_code'
                        },
                        {
                            label: 'Same Cell', 
                            value: 'cell'
                        },
                    ]
                } else {
                    this.orderBy = [
                        {
                            label: 'All Data', 
                            value: ''
                        },
                        // {
                        //     label: 'Same Area Code', 
                        //     value: 'area_code'
                        // },
                        // {
                        //     label: 'Same Cell', 
                        //     value: 'cell'
                        // },
                    ]
                }
                
                this.setData(this.compare_target_detail)
            }
        },
        filterTarget(){
            // this.getData();
        },
        route(newData, oldData) {
            this.setEmptyMap();
            // this.mapHistoryLoaded = false;
            if (newData.name === 'pageCompareTargetDetail') {
                if (oldData !== 'pageCompareTargetDetail') {
                    this.optFilterTarget= []
                    this.filterTarget= ''
                    this.paramMsisdn= []
                    this.items_per_page = 10000
                    this.page = 0
                    this.keyword = ''
                    this.$store.commit('compareTarget/setMonitoringList', {})
                    this.$store.commit('compareTarget/setStatusMonitoringList', {cancel: null, code: null, status: 'loading'})
                }

                // if (this.compare_target_detail != undefined && this.compare_target_detail.target != undefined) {
                // if (this.compare_target_detail != undefined && this.compare_target_detail.target_history != undefined) {
                //     this.setData(this.compare_target_detail)
                // }
            }
        },
        order_by(newData){
            // if (newData != '') {
            this.items_per_page = 10000
            this.page = 0
            // } else {
            //     this.items_per_page = 10000
            // }
        },
        currentClickMarker(newData){
            // console.log({newData})
            if (!newData){
                console.log('watch currentClickMarker null value')
                return
            }
            const google = this.google; 
            const map = this.$refs.mapRef
            const infoWindow = this.gInfoWIndow; 
            // console.log('watch currentClickMarker')
            google.maps.event.trigger(newData, 'click');

            infoWindow.close()
            infoWindow.setContent(newData.desc);
            infoWindow.open(map, newData);
            // console.log('currentClickMarker null')
            // this.currentClickMarker=null
        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.$store.commit('locationEs/setList', {});
        this.setEmptyMap();
        // this.mapHistoryLoaded = false;
        if (this.route.matched[1].name === 'pageCompareTargetDetail') {
            this.getData(true);
            // reset date filter
            // const default_date = this.$store.getters['config/getDefaultDate'](14);

            // this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD').toDate();
            // this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD').toDate();

            // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            // this.getMonthInfo()

            // // if (this.compare_target_detail != undefined && this.compare_target_detail.target != undefined) {
            // if (this.compare_target_detail != undefined && this.compare_target_detail.target_history != undefined) {
            //     this.setData(this.compare_target_detail)
            // }
        }
        // loaded.then(()=>{
        //     import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
        // });

    },
}
</script>

<style lang="sass">

.marker-cluster
    span
        mix-blend-mode: difference

img[src$="#custom_marker"]
    border: 2px solid white !important
    border-radius: 50% !important

.found-result
    background-color: rgba(255,255,255,.8)
    // background-color: #fff
    margin-left: 0px
    height: auto !important
    padding: 17px !important
    margin: 8px !important
    width: 200px !important
    span
        color: #000 !important
        font-size: 1.2em
    select
        height: auto

@media screen and (max-width: 480px)
    .found-result
        height: 25% !important

// #map__footer
//     display: none

.map-filter
    height: 32px
    margin-right: 8px
    border: none
    text-align: center
    width: 100%
#bodyContent
    color: #000
</style>