<template lang="pug">
.info-target-area(:class="{'info-target-area--column' : landscapeMap === true}")
  .info-target-area__map
      .box-map-territory(:class="{'box-map-territory--full-screen' : mapFullScreen === true}")
          .map-settings(v-if="mapHistoryLoaded && location_es_list.list && location_es_list.list.data && location_es_list.list.data.length > 0")
              button(@click="toggleFullScreen()")
                  template(v-if="!mapFullScreen")
                      IconSet(type="maximize")
                  template(v-else)
                      IconSet(type="minimize")

              button(@click="toggleLandscape()" v-if="!mapFullScreen")
                  IconSet(type="landspace")

          
          .block.box-map.flex.items-center.justify-center(v-if="isLoading")
              Loading(show="true")

          .box-map-territory__frame(v-else)
              template(v-if="mapHistoryLoaded && location_es_list.list && location_es_list.list.data && location_es_list.list.data.length > 0")
                l-map#mapCompareLocOSM(v-if="mapVendor==='osm'" ref="mapRefOSMTargetLoc" :id="idOSM" :zoom="10" :options="options" style="height: 100%;width: 100%;")
                  l-tile-layer(:url="url" :attribution="attribution")
                  l-marker(v-if="locations.length > 0" v-for="(location, index) in locations" :lat-lng="location.position" :key="index" :ref="'marker_'+location.index" :icon="getMarkerOsmIcon(location)")
                      l-popup(:content="location.name")
                  template(v-if="Object.keys(locations_cluster).length > 0")
                      //- l-marker-cluster(v-for="(location, indexl) in locations_cluster" :options="cluster_opt" :key="indexl" :ref="'marker_cluster'")
                      l-marker-cluster(v-for="(location, indexl) in locations_cluster" :options="cluster_opt" :key="indexl" :ref="'marker_cluster_'+indexl")
                          l-marker(v-for="(loc, index) in location" :lat-lng="loc.position" :key="index" :ref="'marker_'+loc.index" :icon="getMarkerOsmIcon(loc)")
                              l-popup(:content="loc.name")
                  l-polyline-decorator(v-if="showPolylineOsm && polyline.latlngs.length > 0" 
                  :paths="[polyline.latlngs]" :patterns="patterns")
                  l-polyline(v-if="showPolylineOsm && polyline.latlngs.length > 0" :lat-lngs="polyline.latlngs" :color="polyline.color")

                  LeafletHeatmap(v-if="latLngHeat.length > 0 && showHeatmapOsm" :lat-lng="latLngHeat" :max="maxHeat" :radius="radHeat" :maxZoom="10")
                  l-control-polyline-measure(:options="{ showUnitControl: true }" position="bottomleft")
                  l-control(position="bottomright")
                      .found-result.is-show
                          .mb-4
                              .ui-checkbox
                                  input(id="toggle_lines" name="lines" type="checkbox" @change="toggleLines($event)")
                                  .style-checkbox.flex.items-center
                                      .checkbox.flex.items-center.justify-center
                                          IconSet(type="check")
                                      span Lines
                          .mb-4
                              .ui-checkbox
                                  input(id="toggle_heatmap" name="heatmap" type="checkbox" @change="toggleHeatmap($event)")
                                  .style-checkbox.flex.items-center
                                      .checkbox.flex.items-center.justify-center
                                          IconSet(type="check")
                                      span Heatmap
                          .found-result__footer(id="map__footer")
                              span.block.mb-4 Heatmap Radius (meter)
                              .list-size.field
                                  select.map-filter.field-style.h-10(id="radius-size" name="radius-size" @change="radiusHeatmap($event)")
                                      option(value="10") 10
                                      option(value="20") 20
                                      option(value="30" selected) 30
                                      option(value="40") 40

                gmap-map#mapCompareLocGMAP(v-else ref="mapRefTargetLoc" :zoom="11" :options="options" style="width:100%; height: 100%;")
              
              NoDataAvailable.h-full(v-else)
  .w-full(v-if="isLoading")
      Loading(show="true")        
  
  .info-target-area__table.pr-8(v-else)
      .box-table(v-if="location_es_list && location_es_list.list")
          //- <v-client-table ref='myTable' :data="location_es_list.list.data" :columns="columns" :options="options" @pagination="pageChange($event)" @limit="limitChange($event)" @loaded="loaded()">
          <v-client-table ref='myTable' :data="location_es_list.list.data" :columns="columnsTable" :options="optionsTable">
              span(slot="target" slot-scope="{ row, index }")
                  .flex.items-center.gap-3
                      figure.w-6.h-6.rounded-full.overflow-hidden
                          img.w-full.h-full(:src="getTargetPhoto(row.features[0].properties.msisdn)" @error="getDefaultImage")
                      //- span.text-sm {{getTargetName(row.features[0].properties.msisdn)}}
                      .cursor-pointer.link.text-sm(@click="showPopup((index-1), row.features[0].geometry.coordinates)") 
                          .flex.flex-col
                              span {{getTargetName(row.features[0].properties.msisdn)}}
                              span {{row.features[0].properties.msisdn}}
              span(slot="label" slot-scope="props").col-target-label
                  .target-label(:style="'background-color: '+ (getTargetLabel(props.row.features[0].properties.msisdn)) +';'")
              //- span(slot="msisdn" slot-scope="props") 
                  .text-xs {{props.row.features[0].properties.msisdn ? datetimeFormat(props.row.features[0].properties.msisdn) : '-'}}   
              span(slot="lac" slot-scope="props") 
                  .text-xs {{ props.row.features[0].properties && props.row.features[0].properties.lac ? props.row.features[0].properties.lac : (props.row.features[0].properties && props.row.features[0].properties.tac ? props.row.features[0].properties.tac : '-') }}                                     
              span(slot="ci" slot-scope="props") 
                  .text-xs(v-tooltip="props.row.features[0].properties && props.row.features[0].properties.bts ? props.row.features[0].properties.bts : '-'") {{ props.row.features[0].properties && props.row.features[0].properties.ci ? props.row.features[0].properties.ci : '-' }}                                      
              span(slot="rat" slot-scope="props") 
                  .text-xs {{ props.row.features[0].properties && props.row.features[0].properties.net ? props.row.features[0].properties.net : (props.row.features[0].properties && props.row.features[0].properties.rat ? props.row.features[0].properties.rat:'-') }}                                     
              span(slot="datetime" slot-scope="props") 
                  .text-xs {{props.row.features[0].properties.dtm ? datetimeFormat(props.row.features[0].properties.dtm) : '-'}}
              span(slot="maps" slot-scope="props").text-center.w-10.p-0
                  div.inline-flex.items-center
                      template(v-if="props.row.features[0].geometry.coordinates[0]")
                          a.btn-small.btn-default(:href="'https://www.google.com/maps?q='+props.row.features[0].geometry.coordinates[1]+','+props.row.features[0].geometry.coordinates[0]" target='_blank' v-tooltip="'Google Maps'")
                              IconSet(type="maps")
                      template(v-else)
                          | -

          </v-client-table>  
</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import { env } from '@/config/env';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Spinner from '@/components/Spinner.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { gmapApi } from 'vue2-google-maps';
// import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import MeasureTool from 'measuretool-googlemaps-v3';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

// openstreetmap
import { LMap, LTileLayer, LMarker, LPopup, LPolyline, LControl } from 'vue2-leaflet';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = ''
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import LeafletHeatmap from '@/components/LeafletHeatmap.vue';

export default {
  name: 'TargetLocationCompareTarget',
  components: {
    IconSet,
    Spinner,
    Loading,
    NoDataAvailable,
    VueAdsPagination,
    VueAdsPageButton,
    MeasureTool,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LeafletHeatmap,
    LPolyline,
    LControl,
    LControlPolylineMeasure,
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    'l-polyline-decorator': Vue2LeafletPolylineDecorator,
    Location,
  },
  data() {
    return {
      // patterns,
      landscapeMap: false,
      mapFullScreen: false,
      openAdvanceFilter: false,
      filterTime: false,

      showFilter: false,
      // columnsTable: ['target','label','msisdn','lac','ci','rat','datetime','maps'],
      columnsTable: ['target', 'label', 'lac', 'ci', 'rat', 'datetime', 'maps'],
      optionsTable: {
        headings: {
          'target': 'Target',
          'label': '',
          // 'msisdn': 'MSISDN',
          'lac': 'LAC',
          'ci': 'CI',
          'rat': 'RAT',
          'datetime': 'Datetime',
          'maps': '',
        },
        filterable: false,
        sortable: [],
        // perPage: 10,
        pagination: {
          chunk: 5
          // dropdown: false 
        },
        // perPageValues: [10,25,50,100],
        skin: 'table table-overflow',
        // skin: 'table-static w-full p-4',
        sortIcon: {
          base: 'iconfont',
          is: 'iconsort',
          up: 'iconup',
          down: 'icondown'
        },
        columnsClasses: {
          'datetime': 'font-mini',
        },
        // orderBy:{column:false}
      },
      tableThead: [
        {
          name: 'Target',
        },
        {
          name: '',
        },
        {
          name: 'MSISDN',
        },
        {
          name: 'LAC',
        },
        {
          name: 'CI',
        },
        // {
        //     name: 'BTS',
        // },
        {
          name: 'RAT',
        },
        {
          name: 'Datetime',
        },
        // {
        // name: 'Requested At',
        // },
        {
          name: '',
          action: true,
        },
      ],

      optFilterTarget: [],
      filterTarget: '',
      paramMsisdn: [],
      total_data: 0,
      items_per_page: 10000,

      size: [10, 25, 50, 100],

      page: 0,
      start: 0,
      end: 0,

      months: [],
      btnSelectedMonth: [],

      filterTimeBy: '',
      tmpSelectedTime: [],
      selectedTimeByDate: {},
      selectedTimeAll: [],

      // map history
      mapVendor: this.$store.getters['config/getMapVendor'],
      // mapVendor: 'gmap',
      infoWindowsHistory: [],
      mapHistoryLoaded: false,
      markers_history: [],
      mapsLatLngHistory: [], // map history
      mapsLatLngTarget: {}, // map history
      // mapsLatLngTarget: [], // map history
      polyline_history: null, // map history
      measureTool: null, // map history
      measureToolStatus: false, // map history
      options: {
        zoomControlOptions: {
          position: 1,
          // 1 = TOP_LEFT
          // 2 = TOP_CENTER
          // 3 = TOP_RIGHT
        },
        fullscreenControl: false,
        gestureHandling: 'cooperative',
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }]
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }]
          }
        ],
      },

      // openstreetmap
      // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      locations: [],
      locations_cluster: [],
      polyline: {
        latlngs: [],
        color: 'red'
      },
      // gmap
      infoWindows: [],
      center: {},
      markers: [],
      // places: [],
      heatmap: null,
      mapsLatLng: [],

      idOSM: 'compare_target_loc_target_location',
      newLoc: '',
      newLt: 0,
      newLng: 0,
      icon: L.icon({ iconUrl: "null", }),
      imgAvatar: this.$store.getters['config/getDefaultImageProfile'],
      cluster_opt: {
        // spiderfyOnMaxZoom: true,
        // showCoverageOnHover: true,
        // zoomToBoundsOnClick: true
      },
      bounds: [],

      // icon_colors: [],
      latLngHeat: [],
      maxHeat: 1.0,
      radHeat: 30,

      // showMarkerOsm: true,
      showPolylineOsm: false,
      showHeatmapOsm: false,

      currentClickMarker: null,
      spideredMarker: [],

      gInfoWIndow: null,
    }
  },
  computed: {
    ...mapState({
      compare_target_detail: (state) => state.compareTarget.detail,
      status_compare_target_detail: (state) => state.compareTarget.status_detail,
      location_es_list: (state) => state.locationEs.list,
      status_location_es_list: (state) => state.locationEs.status_list,
    }),
    route() {
      return this.$route;
    },
    route_name() {
      return this.$route.name;
    },
    id() {
      return this.$route.params.id;
    },
    google: gmapApi,
    classObject() {
      return true
    },
    patterns() {
      return [
        { offset: 12, repeat: 25, symbol: L.Symbol.arrowHead({ pixelSize: 10, pathOptions: { color: this.polyline && this.polyline.color ? this.polyline.color : '#000', weight: 2 } }) },
        { offset: 0, repeat: 25, symbol: L.Symbol.arrowHead({ pixelSize: 0 }) }
      ]
    },
    isLoading() {
      if (this.status_location_es_list.status === 'loading' || this.status_compare_target_detail === 'loading') {
        return true
      }
      return false
    },
  },
  methods: {
    animatePolyline(line) {
      let count = 0;

      window.setInterval(() => {
        count = (count + 1) % 200;

        const icons = line.get("icons");

        icons[0].offset = count / 2 + "%";
        line.set("icons", icons);
      }, 100);
    },
    showPopup(index, coor) {
      if (this.mapVendor == 'osm') {
        try {
          // console.log('coor', coor)
          const marker = this.$refs['marker_' + index][0].mapObject
          const map = this.$refs.mapRefOSMTargetLoc.mapObject
          // console.log('map')
          // console.log(map)
          // if (this.bounds.length){
          //     map.fitBounds(L.latLngBounds([this.bounds]));
          // }
          map.fitBounds(L.latLngBounds([[coor[1], coor[0]]]));
          // console.log(marker)
          marker.openPopup()
          console.log('showPopUp marker')
        } catch (error) {
          const marker = this.$refs['marker_' + index + "_cluster"][0].mapObject
          // console.log('marker in cluster')
          // console.log(marker)
          const cluster = this.$refs['marker_cluster_' + coor[1] + ',' + coor[0]]
          // console.log('cluster')
          // console.log(cluster)
          for (let index = 0; index < cluster.length; index++) {
            const elem = cluster[index].mapObject
            // console.log('elem')
            // console.log(elem)
            elem.zoomToShowLayer(marker, function () {
              marker.openPopup();
            });
            // marker.openPopup();
            // console.log('showPopUp cluster')
          }
        }
      } else {
        const google = this.google;
        const map = this.$refs.mapRefTargetLoc
        const markerLatLng = this.markers_history[index].getPosition()
        // console.log(map)
        map.panTo(markerLatLng);
        // console.log('map.panTo')
        try {
          const mapObj = map.$mapObject
          // console.log('mapObj', mapObj)
          const mapZoom = mapObj.zoom
          // console.log('mapZoom', mapZoom)
          if (mapZoom < 17) {
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(markerLatLng)
            // console.log('bounds.extend')
            map.fitBounds(bounds);
            // console.log('map.fitBounds')
          }
          // console.log('sukses map.fitBounds(bounds)')
        } catch (error) {
          console.log('=====error', error)
          // console.log('error map.fitBounds(bounds)')
        }

        // google.maps.event.trigger(this.markers_history[index], 'click');
        // console.log('this.currentClickMarker:pre')
        this.currentClickMarker = this.markers_history[index]
        // console.log('this.currentClickMarker:post')
      }
    },
    getTargetName(msisdn) {
      var target = this.optFilterTarget.filter(val => val.msisdn == msisdn)
      if (target.length > 0) {
        return target[0].name
      } else {
        return '-'
      }
    },
    getTargetPhoto(msisdn) {
      var target = this.optFilterTarget.filter(val => val.msisdn == msisdn)
      if (target.length > 0) {
        try {
          return target[0].photo ? target[0].photo : '-'
        } catch (error) {
          // console.log(error)
        }
      }
      return '-'
    },
    getTargetLabel(msisdn) {
      var target = this.optFilterTarget.filter(val => val.msisdn == msisdn)
      if (target.length > 0) {
        return target[0].label
      } else {
        return 'white'
      }
    },
    getTargetData(msisdn) {
      var target = this.optFilterTarget.filter(val => val.msisdn == msisdn)
      if (target.length > 0) {
        return target[0]
      } else {
        return '-'
      }
    },
    setData(data) {
      // console.log('=====setData', data)
      // this.mapHistoryLoaded = false;
      this.optFilterTarget = []
      this.filterTarget = ''
      this.paramMsisdn = []
      // var targets = data.target
      let pushMsisdnTarget = true
      if (![null, ''].includes(data.first_target_msisdn)) {
        this.paramMsisdn.push(data.first_target_msisdn)
        pushMsisdnTarget = false
      }
      var first = data.first_target
      for (var i = 0; i < first.clues.length; i++) {
        if (first.clues[i].name == 'msisdn') {
          if (pushMsisdnTarget) {
            this.paramMsisdn.push(first.clues[i].value)
          }
          var tmpTarget = {
            'id': first.id,
            'name': first.name,
            'msisdn': first.clues[i].value,
            'label': first.label,
            'photo': first.photo,
          }
          this.optFilterTarget.push(tmpTarget)
        }
      }
      let pushMsisdnTarget2 = true
      if (![null, ''].includes(data.second_target_msisdn)) {
        this.paramMsisdn.push(data.second_target_msisdn)
        pushMsisdnTarget2 = false
      }
      var second = data.second_target
      for (var j = 0; j < second.clues.length; j++) {
        if (second.clues[j].name == 'msisdn') {
          if (pushMsisdnTarget2) {
            this.paramMsisdn.push(second.clues[j].value)
          }
          var tmpTarget2 = {
            'id': second.id,
            'name': second.name,
            'msisdn': second.clues[j].value,
            'label': second.label,
            'photo': second.photo,
          }
          this.optFilterTarget.push(tmpTarget2)
        }
      }

    },
    datetimeFormat(datetime) {
      // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY:ssZ');
      return datetime;
    },
    /* eslint-disable no-unused-vars */
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = moment(date.getTime()).subtract({ hours: 12 })._d > new Date();
      }
      return classes;
    },
    getData(first = false) {
      this.setEmptyMap();
      // this.mapHistoryLoaded = false;

      var msisdn = null
      if (this.filterTarget) {
        msisdn = this.filterTarget
      } else {
        msisdn = JSON.stringify(this.paramMsisdn)
      }
      let fromDate = this.from_date
      let toDate = this.to_date
      if (Object.keys(this.selectedTimeByDate).length > 0) {
        let tmpFromDate = []
        let tmpToDate = []
        for (var key of Object.keys(this.selectedTimeByDate)) {
          // console.log(key + " -> " + this.selectedTimeByDate[key])
          this.selectedTimeByDate[key].forEach(element => {
            const tmpTime = element.split("-")
            // console.log(element + " -> " + element.split("-"))
            tmpFromDate.push(key + ' ' + tmpTime[0])
            tmpToDate.push(key + ' ' + tmpTime[1])
          });
        }
        fromDate = JSON.stringify(tmpFromDate)
        toDate = JSON.stringify(tmpToDate)
      }
      // console.log('===fromDate:',fromDate)
      // console.log('===toDate:',toDate)
      // this.$store.dispatch('locationEs/getList', ['list_advance', msisdn, this.items_per_page, (this.page + 1), fromDate, toDate, '', 'available_coordinate', '', this.order_by]);
    },
    pageChange(page) {
      this.page = page;
    },
    rangeChange(start, end) {
      this.start = start;
      this.end = end;
    },
    sizeChange() {
      this.page = 0;
    },
    getDefaultImage(e) {
      e.target.src = this.imgAvatar;
    },
    hideAllInfoWindows(history = false) {
      for (var i = 0; i < this.infoWindowsHistory.length; i++) {
        this.infoWindowsHistory[i].close();
      }
    },
    createInfoWindow(name = '-', msisdn = '-', datetime = '-', lat_lng = '', address = '') {
      var contentString = '<div id="content">' +
        '<div id="bodyContent">' +
        '<table>' +
        '<tr>' +
        '<td class="pr-1 cell-info-window">Target</td>' +
        '<td class="pr-1 cell-info-window">:</td>' +
        '<td class="cell-info-window">' + name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="cell-info-window">MSISDN</td>' +
        '<td class="cell-info-window">:</td>' +
        '<td class="cell-info-window">' + msisdn + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="cell-info-window">Datetime</td>' +
        '<td class="cell-info-window">:</td>' +
        '<td class="cell-info-window">' + datetime + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="cell-info-window">Address</td>' +
        '<td class="cell-info-window">:</td>' +
        '<td class="cell-info-window">' + address + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td class="cell-info-window">Link Location</td>' +
        '<td class="cell-info-window">:</td>' +
        '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q=' + lat_lng + '" style="color: #43ea92;">' + 'Google Maps</a> ' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</div>' +
        '</div>';

      return contentString;
    },
    getMarkerOsmIcon(location) {
      let classNm = ''
      let type = 'default'
      let myIconUrl = ''
      if (location.photo) {
        myIconUrl = location.photo
        classNm = 'map-point map-point--map-avatar'
        type = 'photo'
      }
      if (type == 'photo') {
        return L.icon({
          iconUrl: myIconUrl,
          className: classNm,
          iconSize: [50, 50],
          iconAnchor: [24.5, 49],
          popupAnchor: [0, -48],
        });
      } else {
        let iconColor = '#ffffff'
        if (location.iconColor) {
          iconColor = location.iconColor
        }
        let stroke_width = '1.8';
        let stroke = 'white';
        let mySvgString = '<svg width="64" height="64" viewBox="0 0 64 64" fill="<color-icon>" xmlns="http://www.w3.org/2000/svg"><path d="M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z" fill="<color-icon>" stroke="' + stroke + '" stroke-width="' + stroke_width + '"/><path d="M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z" fill="<color-icon>" stroke="' + stroke + '" stroke-width="' + stroke_width + '" stroke-linejoin="round"/></svg>'
        myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replace(/<color-icon>/g, iconColor)).replace(/#/g, '%23');

        return L.icon({
          iconUrl: myIconUrl,
          iconSize: 50,
          iconAnchor: [24.5, 49],
          popupAnchor: [0, -48],
        });
      }
    },
    async startMapHistory() {
      try {
        console.log('startMapHistory')
        var self = this;
        this.locations = [];
        this.locations_cluster = [];

        this.center = {
          lat: -6.1763562,
          lng: 106.8227796,
        };

        this.mapHistoryLoaded = true;

        // osm
        let check_coordinates = [];
        let check_coordinates_cluster = [];
        let check_coordinates_duplicate = [];
        let locations = [];
        let locations_cluster = [];

        self.latLngHeat = [];

        // gmap
        self.infoWindows = [];
        self.markers = [];
        self.heatmap = null;
        self.mapsLatLng = [];
        self.polyline = {
          latlngs: [],
          color: 'red'
        };

        self.bounds = [];


        switch (self.mapVendor) {
          case 'osm':
            // await self.$nextTick(() => {
            self.$nextTick(() => {
              console.log("nexttick targetloc")
              let map = self.$refs.mapRefOSMTargetLoc

              if (!map)
                return;

              map = map.mapObject;

              if (!map)
                return;

              let heatmapData = {};
              let maxValue = 0;
              let heatmapDataFix = [];

              if (self.location_es_list.list && self.location_es_list.list.data && self.location_es_list.list.data.length > 0) {
                console.log("location_es_gooddddd")
                var data = self.location_es_list.list.data;
                // marker
                for (var i = 0; i < data.length; i++) {
                  // console.log(i);
                  if (data[i].features[0].geometry.coordinates.length > 0 && data[i].features[0].geometry.coordinates[1] != '') {
                    var target = self.getTargetData(data[i].features[0].properties.msisdn)
                    let latTmp = parseFloat(data[i].features[0].geometry.coordinates[1]);
                    let lngTmp = parseFloat(data[i].features[0].geometry.coordinates[0]);

                    if (heatmapData[latTmp + ',' + lngTmp]) {
                      heatmapData[latTmp + ',' + lngTmp][2] += 1;
                    } else {
                      heatmapData[latTmp + ',' + lngTmp] = [latTmp, lngTmp, 1];
                    }

                    if (maxValue < heatmapData[latTmp + ',' + lngTmp][2]) {
                      maxValue = heatmapData[latTmp + ',' + lngTmp][2]
                    }

                    var dtm = (data[i].features[0].properties.dtm ? self.datetimeFormat(data[i].features[0].properties.dtm) : '-');

                    var contentString = self.createInfoWindow(target.name, data[i].features[0].properties.msisdn, dtm, (data[i].features[0].geometry.coordinates[1] + ',' + data[i].features[0].geometry.coordinates[0]), data[i].features[0].properties.alamat);
                    let centerTmp = [latTmp, lngTmp]
                    let labelColor = target.label
                    let tmpPhoto = ''
                    try {
                      tmpPhoto = target.photo ? target.photo : ''
                    } catch (error) {
                      console.log('=====error', error)
                    }

                    if (check_coordinates.includes(centerTmp.toString())) {
                      if (check_coordinates_duplicate.includes(centerTmp.toString()) === false) {
                        check_coordinates_duplicate.push(centerTmp.toString());
                      }
                      check_coordinates_cluster.push(centerTmp.toString());

                      locations_cluster.push({
                        index: i + '_cluster',
                        name: contentString, position: centerTmp,
                        iconColor: labelColor,
                        photo: tmpPhoto,
                      });
                    } else {
                      check_coordinates.push(centerTmp.toString());

                      locations.push({
                        index: i,
                        name: contentString, position: centerTmp,
                        iconColor: labelColor,
                        photo: tmpPhoto,
                      });
                      // self.locations.push({name:contentString,position:centerTmp, iconColor:labelColor});
                    }

                    // target.id in self.mapsLatLngTarget || 
                    if (!self.mapsLatLngTarget[target.id]) {
                      self.mapsLatLngTarget[target.id] = {};
                    }
                    if (!self.mapsLatLngTarget[target.id].coor) {
                      self.mapsLatLngTarget[target.id].coor = [];
                    }
                    if (!self.mapsLatLngTarget[target.id].label) {
                      self.mapsLatLngTarget[target.id].label = '#fff';
                    }

                    // self.mapsLatLngTarget[target.id].coor.push({lat: parseFloat(data[i].features[0].geometry.coordinates[1]), lng: parseFloat(data[i].features[0].geometry.coordinates[0]))} )
                    self.mapsLatLngTarget[target.id].coor.push(centerTmp);
                    self.mapsLatLngTarget[target.id].label = labelColor;

                    // self.polyline.latlngs.push(centerTmp)
                    // self.polyline.color = labelColor

                    this.bounds.push(centerTmp);
                  }
                }

                // heatmap
                if (maxValue > 0) {
                  Object.values(heatmapData).forEach(function (arr) {
                    arr[2] = arr[2] / maxValue;
                    heatmapDataFix.push(arr);
                  });
                  // console.log({heatmapDataFix})
                  self.latLngHeat = heatmapDataFix;
                }
                if (check_coordinates.length > 0 || check_coordinates_cluster.length > 0) {
                  const delete_location_index = [];

                  for (var i = 0; i < check_coordinates.length; i++) {
                    for (let j = 0; j < check_coordinates_duplicate.length; j++) {
                      if (check_coordinates[i] === check_coordinates_duplicate[j]) {
                        locations[i].index = locations[i].index + '_cluster'
                        locations_cluster.push(locations[i]);
                        delete_location_index.push(i);
                      }
                    }
                  }

                  if (delete_location_index.length > 0) {
                    // for (var i = 0; i < delete_location_index.length; i++) {
                    //     locations.splice(delete_location_index[i], 1);
                    // }

                    for (var i = delete_location_index.length - 1; i >= 0; i--) {
                      // console.log(delete_location_index[i])
                      locations.splice(delete_location_index[i], 1);
                    }
                  }
                  self.locations = locations;

                  locations_cluster = locations_cluster.reduce((r, a) => {
                    // console.log("a", a);
                    // console.log('r', r);
                    r[a.position] = [...r[a.position] || [], a];
                    return r;
                  }, {});

                  // console.log(locations_cluster)
                  self.locations_cluster = locations_cluster;
                }

                if (Object.keys(self.mapsLatLngTarget).length > 0) {
                  for (const [key, value] of Object.entries(self.mapsLatLngTarget)) {
                    self.polyline.latlngs.push(value.coor);
                    self.polyline.color = value.label;
                  }
                }

                if (this.bounds.length) {
                  map.fitBounds(L.latLngBounds([this.bounds]));
                }
              }
            });
            // });
            break;

          default:
            self.$nextTick(() => {
              self.$refs.mapRefTargetLoc.$mapPromise.then((map) => {
                const google = self.google;
                self.gInfoWIndow = new google.maps.InfoWindow();

                if (self.location_es_list.list && self.location_es_list.list.data && self.location_es_list.list.data.length > 0) {
                  var data = self.location_es_list.list.data
                  // const infoWindow = new google.maps.InfoWindow();
                  const infoWindow = self.gInfoWIndow;
                  const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier')
                  // const OverlappingMarkerSpiderfier = self.OverlappingMarkerSpiderfier

                  // const oms = new OverlappingMarkerSpiderfier(map)
                  const oms = new OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,
                    markersWontHide: true,
                    keepSpiderfied: true,
                    // circleFootSeparation:69,
                    nudgeStackedMarkers: false,
                  });

                  google.maps.event.addListener(map, "click", function (event) {
                    infoWindow.close();
                  });

                  oms.addListener('click', function (marker) {
                    self.currentClickMarker = marker
                    // infoWindow.setContent(marker.desc);
                    // infoWindow.open(map, marker);
                  });

                  oms.addListener('unspiderfy', function (markers) {
                    // console.log('unspiderfy markers')
                    var spidered = markers

                    for (var i = 0; i < spidered.length; i++) {
                      let idMark = spidered[i].mark_id
                      let index = self.spideredMarker.indexOf(idMark);
                      if (index > -1) {
                        self.spideredMarker.splice(index, 1);
                      }

                      let testIcon = spidered[i].cust_icon.icon
                      let testIconMerge = spidered[i].cust_icon.iconMerge
                      spidered[i].setIcon(testIconMerge);
                    }
                    self.currentClickMarker = null
                  });

                  // google.maps.event.addListener(infoWindow,'closeclick',function(){
                  // infoWindow.addListener('closeclick',function(){
                  //     console.log('closeclick infoWindow!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                  //    self.currentClickMarker = null
                  // });

                  oms.addListener('spiderfy', function (markers) {
                    // console.log('spiderfy markers')
                    // console.log(markers.length)
                    var spidered = markers;
                    var firstData = null;

                    for (var i = 0; i < spidered.length; i++) {

                      let testIcon = spidered[i].cust_icon.icon
                      let idMark = spidered[i].mark_id
                      let testIconMerge = spidered[i].cust_icon.iconMerge
                      spidered[i].setIcon(testIcon);
                      self.spideredMarker.push(idMark)
                    }
                    if (firstData)
                      self.currentClickMarker = firstData
                  });

                  google.maps.event.addListener(map, 'zoom_changed', function () {
                    infoWindow.close();

                    google.maps.event.addListener(map, 'idle', function () {
                      // console.log('self.checkMarker++++++++++++++++++++++++++++++++++++++:BEGIN')
                      // if (self.checkMarker){
                      var spidered = oms.markersNearAnyOtherMarker();
                      // console.log('spidered.length before')
                      // console.log(spidered.length)
                      spidered = spidered.filter(function (v) {
                        return !self.spideredMarker.includes(v.mark_id);
                      });
                      // console.log('spidered.length after')
                      // console.log(spidered.length)

                      let tmp = null

                      if (spidered.length) {
                        tmp = self.currentClickMarker;
                        self.currentClickMarker = null;
                        // console.log('call markersNearAnyOtherMarker-------------------------------------------');
                      }

                      for (var i = 0; i < spidered.length; i++) {
                        let testIcon = spidered[i].cust_icon.icon;
                        let testIconMerge = spidered[i].cust_icon.iconMerge;
                        spidered[i].setIcon(testIconMerge);
                      }

                      // console.log({tmp})
                      // if (tmp){
                      //     self.currentClickMarker = tmp;
                      //     // console.log('call markersNearAnyOtherMarker-------------------------------------------dfg');
                      //     window.setInterval(() => {
                      //         self.currentClickMarker = null;
                      //     }, 100);
                      // }
                      // console.log('self.checkMarker++++++++++++++++++++++++++++++++++++++:END');

                    });

                  });

                  var bounds = new google.maps.LatLngBounds();

                  let i;
                  // let checkLoc = []
                  var iconMerge = {
                    url: '/images/green-people.svg',
                    scaledSize: new google.maps.Size(50, 50)  // makes SVG icons work in IE
                  }

                  // marker
                  for (i = 0; i < data.length; i++) {
                    // console.log(i);
                    if (data[i].features[0].geometry.coordinates.length > 0 && data[i].features[0].geometry.coordinates[1] != '') {
                      var target = self.getTargetData(data[i].features[0].properties.msisdn)
                      // console.log("coord")
                      // console.log(parseFloat(data[i].features[0].geometry.coordinates[1])+ ', '+parseFloat(data[i].features[0].geometry.coordinates[0]))
                      // console.log('target')
                      // console.log(target)
                      // console.log(target.label)

                      // let checkLatLng = ([parseFloat(data[i].features[0].geometry.coordinates[1]), parseFloat(data[i].features[0].geometry.coordinates[0])]).join("|")

                      const location = new google.maps.LatLng(parseFloat(data[i].features[0].geometry.coordinates[1]), parseFloat(data[i].features[0].geometry.coordinates[0]))
                      bounds.extend(location);

                      self.mapsLatLngHistory[i] = location;
                      // target.id in self.mapsLatLngTarget || 
                      if (!self.mapsLatLngTarget[target.id]) {
                        self.mapsLatLngTarget[target.id] = {};
                      }
                      if (!self.mapsLatLngTarget[target.id].coor) {
                        self.mapsLatLngTarget[target.id].coor = [];
                      }
                      if (!self.mapsLatLngTarget[target.id].label) {
                        self.mapsLatLngTarget[target.id].label = '#fff';
                      }

                      self.mapsLatLngTarget[target.id].coor.push(location)
                      self.mapsLatLngTarget[target.id].label = target.label

                      var icon = {
                        path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                        fillColor: target.label,
                        fillOpacity: 1,
                        strokeWeight: 2,
                        strokeColor: '#ffffff',
                        scale: 0.75,
                        anchor: new google.maps.Point(29, 60),
                      }
                      // console.log('======photo')
                      if (target.photo) {
                        // console.log('======ada photo')
                        // console.log(target.photo)
                        icon = {
                          // url: target.photo[0].file+ '#custom_marker',
                          url: target.photo,
                          // url: this.imgAvatar+ '#custom_marker',
                          // size: {width: 50, height: 50, f: 'px', b: 'px',},
                          scaledSize: new google.maps.Size(40, 40),
                          shape: { coords: [17, 17, 18], type: 'circle' },
                        }
                        // console.log('======ada photo2')
                        // console.log(icon)
                      }

                      var marker = new google.maps.Marker({
                        position: location,
                        map: map,
                        icon: icon,
                        // draggable: true
                      });
                      // google.maps.event.addListener(marker, 'click', (function (marker, i) {
                      //     return function () {
                      //         // infowindow.setContent(locations[i][2]);
                      //         infowindow.open(map, marker);
                      //     }



                      // var dtm = (data[i].features[0].properties.dtm ? moment(data[i].features[0].properties.dtm, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMMM YYYY,') : '-');
                      var dtm = (data[i].features[0].properties.dtm ? self.datetimeFormat(data[i].features[0].properties.dtm) : '-');

                      var contentString = self.createInfoWindow(target.name, data[i].features[0].properties.msisdn, dtm, (data[i].features[0].geometry.coordinates[1] + ',' + data[i].features[0].geometry.coordinates[0]), data[i].features[0].properties.alamat);

                      marker.desc = contentString;
                      marker.mark_id = i + 1;
                      marker.cust_icon = {
                        icon: icon,
                        iconMerge: iconMerge,
                        // iconCheck: OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
                      };

                      // console.log('marker.cust_icon')
                      // console.log(marker.cust_icon.iconMerge)
                      // google.maps.event.addListener(marker, 'click', function(marker) {
                      //     infoWindow.setContent(contentString);
                      //     infoWindow.open(map, marker);
                      // });

                      // google.maps.event.addListener(marker, 'spider_format', function(status) {
                      //     let testIcon = marker.cust_icon.icon
                      //     let testIconMerge = marker.cust_icon.iconMerge
                      //     console.log({status, testIconMerge, testIcon})
                      //     marker.setIcon(status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE?testIconMerge: testIcon);
                      // });

                      // })(marker, i));
                      // console.log(marker);
                      self.markers_history.push(marker);

                      // let infowindow = new google.maps.InfoWindow({
                      //     content: contentString,
                      //     maxWidth: 400
                      // });
                      // oms.addListener('click', function(marker) {
                      //     infowindow.setContent(marker.desc);
                      //     infowindow.open(map, marker);
                      // });
                      // google.maps.event.addListener(marker, 'spider_click', function(e) {  
                      //     // 'spider_click', not plain 'click'
                      //     map.panTo(this.getPosition());
                      //     map.setZoom(17);
                      // });

                      oms.addMarker(marker)
                      // oms.addMarker(marker, function(marker) {
                      //     infoWindow.setContent(marker.desc);
                      //     infoWindow.open(map, marker);
                      // });

                      // self.infoWindowsHistory.push(infowindow); 

                      // google.maps.event.addListener(marker, 'click', function() {
                      //     // self.hideAllInfoWindows(true);
                      //     infoWindow.setContent(contentString);
                      //     infoWindow.open(map, marker);
                      //     // map.panTo(this.getPosition());
                      //     // map.setZoom(17);
                      // });

                      // google.maps.event.addListener(
                      //     marker, 
                      //     // 'click', 
                      //     'spider_click', 
                      //     (
                      //         function() {
                      //             infoWindow.setContent(contentString);
                      //             infoWindow.open(map, marker);
                      //         }
                      //     )
                      //     (
                      //         marker, 
                      //         i
                      //     )
                      // );

                      // const oms = new OverlappingMarkerSpiderfier(map,
                      // {
                      //     markersWontMove: true,
                      //     markersWontHide: true,
                      //     nudgeRadius:     0.5,
                      //     minNudgeZoomLevel: 6,
                      // });
                      // const iw = new google.maps.InfoWindow();
                      // oms.addListener('click', function(marker, event) {
                      //     iw.setContent(marker.desc);
                      //     iw.open(map, marker);
                      // });
                      // oms.addListener('spiderfy', function(markers) {
                      //     iw.close();
                      // });
                      // oms.addMarker(marker);  // <-- here
                      // if (!checkLoc[checkLatLng]) {
                      //     checkLoc[checkLatLng] = marker
                      // }
                    }
                    // Apply fitBounds
                  }
                  // self.checkMarker=true

                  // if (Object.keys(checkLoc).length > 0){
                  //     for (const [key, value] of Object.entries(self.mapsLatLngTarget)) {
                  //         check = oms.markersNearMarker(value)
                  //         for (var ind = 0; ind < check.length; ind ++) {
                  //             check[ind].setIcon(iconMerge)
                  //         }
                  //         value.setIcon(iconMerge)
                  //     }
                  // }

                  // google.maps.event.addListener(map, 'idle', function () {
                  //     oms.formatMarkers();
                  // });

                  // console.log('self.mapsLatLngTarget')
                  // console.log(self.mapsLatLngTarget)
                  // console.log(Object.keys(self.mapsLatLngTarget).length)
                  if (Object.keys(self.mapsLatLngTarget).length > 0) {

                    // for (var data in self.mapsLatLngTarget) {
                    for (const [key, value] of Object.entries(self.mapsLatLngTarget)) {
                      // console.log(key, value);
                      // console.log(value);
                      // console.log(value.coor);
                      // console.log(value.label);

                      // console.log('data')
                      // console.log(data)
                      // console.log(self.mapsLatLngHistory.data)
                      // console.log(self.mapsLatLngHistory[data])
                      const lineSymbol = {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 3,
                        strokeColor: value.label,
                      };
                      self.polyline_history = new google.maps.Polyline({
                        path: value.coor,
                        strokeColor: value.label,
                        strokeOpacity: 0.8,
                        strokeWeight: 1.5,
                        icons: [
                          {
                            icon: lineSymbol,
                            offset: "0",
                            repeat: "30px",
                          },
                        ],
                      });
                      // this.animatePolyline(self.polyline_history);

                      // self.polyline_history.setMap(map);
                    }
                    // for (let index = 0; index < self.mapsLatLngTarget.length; index++) {
                    // const element = array[index];
                    // self.polyline_history = new google.maps.Polyline({
                    //     path: self.mapsLatLngHistory,
                    //     strokeColor: '#FF0000',
                    //     strokeOpacity:0.8,
                    //     strokeWeight:1.5
                    // });

                    // self.polyline_history.setMap(map);   
                    // }
                  }
                  self.heatmap = new google.maps.visualization.HeatmapLayer({
                    data: self.mapsLatLngHistory
                  });
                  // self.heatmap.setMap(map);
                  // const renderer = {
                  //     render: ({ count, position }) =>
                  //         new google.maps.Marker({
                  //         label: { text: String(count), color: "white", fontSize: "10px" },
                  //         position,
                  //         // adjust zIndex to be above other markers
                  //         zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                  //     }),
                  // };
                  const renderer = {
                    render({ count, position }, stats) {
                      // change color if this cluster has more markers than the mean cluster
                      // const color = count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
                      const color = count > Math.max(10, stats.clusters.markers.mean) ? "#43EA92" : "#43EA92";
                      // create svg url with fill color
                      const svg = window.btoa(`
                                        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                                            <circle cx="120" cy="120" opacity=".6" r="70" />
                                            <circle cx="120" cy="120" opacity=".3" r="90" />
                                            <circle cx="120" cy="120" opacity=".2" r="110" />
                                        </svg>`);
                      // create marker using svg icon
                      return new google.maps.Marker({
                        position,
                        icon: {
                          url: `data:image/svg+xml;base64,${svg}`,
                          scaledSize: new google.maps.Size(45, 45),
                        },
                        label: {
                          text: String(count),
                          // color: "rgba(255,255,255,0.9)",
                          color: "#101220",
                          fontSize: "12px",
                        },
                        title: `Cluster of ${count} markers`,
                        // adjust zIndex to be above other markers
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                      });
                    }
                  };
                  // Apply marker cluster
                  new MarkerClusterer({
                    map: map,
                    markers: self.markers_history,
                    renderer,
                    maxZoom: 6,
                  });
                  self.measureTool = new MeasureTool(map);
                  // Apply fitBounds
                  map.fitBounds(bounds);
                }

                // controlDiv.classList.add('is-show')
                var toggle_box = '<div class="found-result is-show" style="display: none;">';
                toggle_box += '<div class="">';
                toggle_box += '<div>';
                toggle_box += '<div class="mb-4">';
                // toggle_box += '<label class="switch">';
                // toggle_box += '<input onclick="toggleMarker(this)" name="marker" type="checkbox" checked>';
                // toggle_box += '<span class="slider round"></span>';
                // toggle_box += '</label>';
                // toggle_box += '<div class="ui-checkbox"><input id="toggle_marker" name="marker" type="checkbox" checked><div class="style-checkbox flex items-center"><div class="checkbox flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></div><span>Marker</span></div></div>';

                // toggle_box += '</div>';
                toggle_box += '<div class="mb-4">';
                // toggle_box += '<label class="switch">';
                // toggle_box += '<input onclick="toggleLines()" name="lines" type="checkbox" checked>';
                // toggle_box += '<span class="slider round"></span>';
                // toggle_box += '</label>';
                toggle_box += '<div class="ui-checkbox"><input id="toggle_lines" name="lines" type="checkbox"><div class="style-checkbox flex items-center"><div class="checkbox flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></div><span>Lines</span></div></div>';

                toggle_box += '</div>';
                toggle_box += '<div class="mb-4">';
                // toggle_box += '<label class="switch">';
                // toggle_box += '<input onclick="toggleHeatmap()" name="heatmap" type="checkbox" checked>';
                // toggle_box += '<span class="slider round"></span>';
                // toggle_box += '</label>';
                toggle_box += '<div class="ui-checkbox"><input id="toggle_heatmap" name="heatmap" type="checkbox"><div class="style-checkbox flex items-center"><div class="checkbox flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></div><span>Heatmap</span></div></div>';

                toggle_box += '</div>';
                toggle_box += '</div>';
                toggle_box += '</div>';
                toggle_box += '<div class="found-result__footer" id="map__footer">';
                toggle_box += '<span class="block mb-4">Heatmap Radius (meter)</span>';
                toggle_box += '<div class="list-size field">';
                toggle_box += '<select class="map-filter field-style h-10" id="radius-size" ';
                toggle_box += 'name="radius-size">';
                toggle_box += '<option value="10">10</option>';
                toggle_box += '<option value="20">20</option>';
                toggle_box += '<option value="30" selected>30</option>';
                toggle_box += '<option value="40">40</option>';
                toggle_box += '</select>';
                // icon arrow bottom
                toggle_box += '<div class=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"><svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg></div>'
                toggle_box += '</div>';
                // toggle_box += '<div>';
                // toggle_box += '<button id="toggle_measure_start">start</button>';
                // toggle_box += '<button id="toggle_measure_end"">end</button>';
                // toggle_box += '</div>';
                toggle_box += '</div>';
                toggle_box += '</div>';

                var get_map = document.getElementById('mapHistory');

                var el = document.createElement('div');
                el.innerHTML = toggle_box;

                // this.btnToggleMarker = el.querySelector('#toggle_marker')
                // this.btnToggleMarker.addEventListener('change', this.toggleMarker)

                this.btnToggleLines = el.querySelector('#toggle_lines')
                this.btnToggleLines.addEventListener('change', this.toggleLines)

                this.btnToggleHeatmap = el.querySelector('#toggle_heatmap')
                this.btnToggleHeatmap.addEventListener('change', this.toggleHeatmap)

                this.btnRadiusHeatmap = el.querySelector('#radius-size')
                this.btnRadiusHeatmap.addEventListener('change', this.radiusHeatmap)

                // this.btnMeasureStart = el.querySelector('#toggle_measure_start')
                // this.btnMeasureStart.addEventListener('click', self.measureTool.start())

                // this.btnMeasureEnd = el.querySelector('#toggle_measure_end')
                // this.btnMeasureEnd.addEventListener('click', self.measureTool.end())

                get_map.after(el);
                var controlDiv = document.querySelector('.found-result');
                map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);

                setTimeout(function () {
                  controlDiv.style.display = 'inline';
                }, 1050);

                // Create the DIV to hold the control.
                const centerControlDiv = document.createElement("div");
                // Create the control.
                const centerControl = self.createControlGmap(map);

                // Append the control to the DIV.
                centerControlDiv.appendChild(centerControl);
                map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(centerControlDiv);

              });
            });
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    createControlGmap(map) {
      const controlButton = document.createElement("button");

      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "14px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 8px 0";
      controlButton.style.padding = "0 14px";
      controlButton.style.textAlign = "center";
      controlButton.textContent = "Ruler";
      controlButton.title = "Click to show ruler";
      controlButton.type = "button";
      controlButton.setAttribute("id", "button_measure");
      // Setup the click event listeners: simply set the map to Chicago.
      // const chicago = { lat: 41.85, lng: -87.65 };
      controlButton.addEventListener("click", this.toggleMeasure);
      return controlButton;
    },
    setEmptyMap() {
      console.log('setEmptyMap')
      // map history
      this.mapHistoryLoaded = false;
      this.infoWindowsHistory = [];
      this.markers_history = [];
      this.mapsLatLngHistory = []; // map history
      this.mapsLatLngTarget = {}; // map history
      this.polyline_history = null; // map history

      // osm
      this.locations = [];
      this.locations_cluster = [];
      this.polyline = {
        latlngs: [],
        color: 'red'
      };
    },

    toggleLandscape() {
      this.landscapeMap = !this.landscapeMap
    },
    toggleFullScreen() {
      this.mapFullScreen = !this.mapFullScreen
      const el = document.body

      if (this.mapFullScreen === true) {
        // console.log('body freezee')
        el.classList.add('body-freeze')
      } else {
        // console.log('body oveflow')
        el.classList.remove('body-freeze')
      }
    },
    getMonthInfo() {
      this.selectedTimeAll = []
      this.selectedTimeByDate = {}
      this.months = []
      var from = this.from_date.split(/-| /)
      var to = this.to_date.split(/-| /)

      var year = from[0]
      var month = from[1]
      while ((month <= parseInt(to[1]) && year == parseInt(to[0])) || (month != parseInt(to[1]) + 1 && year < parseInt(to[0]))) {
        const date = new Date();
        date.setMonth(month - 1);
        var getMonthLong = date.toLocaleString('default', { month: 'long' });
        var getMonthShort = date.toLocaleString('default', { month: 'short' });

        var getDays = new Date(year, month, 0).getDate();

        this.months.push({
          name_long: getMonthLong,
          name_short: getMonthShort,
          days: getDays,
          year: year,
          month: month,
          start_day: (month == from[1] && year == from[0]) ? parseInt(from[2]) : 1,
          end_day: (month == to[1] && year == to[0]) ? parseInt(to[2]) : getDays,
        });

        if (month == 12) {
          month = 0
          year++
        }
        month++
      }
      // this.showLoading(false);
    },
    toggleLines(e = null) {
      var self = this;
      switch (self.mapVendor) {
        case 'osm':
          if (e.target.checked) {
            self.showPolylineOsm = true;
          } else {
            self.showPolylineOsm = false;
          }
          break;

        default:
          self.$nextTick(() => {
            self.$refs.mapRefTargetLoc.$mapPromise.then((map) => {
              self.polyline_history.setMap(self.polyline_history.getMap() ? null : map);
            });
          });
      }
    },
    toggleHeatmap(e = null) {
      var self = this;
      switch (self.mapVendor) {
        case 'osm':
          if (e.target.checked) {
            self.showHeatmapOsm = true;
          } else {
            self.showHeatmapOsm = false;
          }
          break;

        default:
          self.$nextTick(() => {
            self.$refs.mapRefTargetLoc.$mapPromise.then((map) => {
              self.heatmap.setMap(self.heatmap.getMap() ? null : map);
            });
          });
      }
    },
    toggleMeasure() {
      // console.log('measureToolStatus before', this.measureToolStatus)
      this.measureToolStatus = !this.measureToolStatus
      // console.log('measureToolStatus after', this.measureToolStatus)
      var measureButton = document.getElementById('button_measure');
      if (this.measureToolStatus) {
        this.measureTool.start()
        measureButton.style.backgroundColor = "#43EA92";
      } else {
        measureButton.style.backgroundColor = "#fff";
        this.measureTool.end()
      }
    },
    radiusHeatmap(e) {
      var self = this;
      switch (self.mapVendor) {
        case 'osm':
          self.radHeat = parseInt(e.target.value);
          break;

        default:
          self.$nextTick(() => {
            self.$refs.mapRefTargetLoc.$mapPromise.then((map) => {
              self.heatmap.set('radius', parseInt(e.target.value));
            });
          });
      }
    },
    async openTab() {
      var tmpStatus = this.status_location_es_list
      await this.$store.commit('locationEs/setStatusList', { status: "loading", code: null });
      this.$store.commit('locationEs/setStatusList', tmpStatus);
      if (this.location_es_list.list && this.location_es_list.list.data && this.location_es_list.list.data.length > 0) {
        this.startMapHistory();
      }
    }
  },
  watch: {
    location_es_list(newData) {
      console.log("watch location_es_list targetloc")
      this.setEmptyMap();
      // this.mapHistoryLoaded = false;
      if (newData && newData.list) {
        if (Object.keys(newData.list).length > 0) {
          this.total_data = parseInt(newData.list.total_data);
        } else {
          this.total_data = 0;
        }
        if (this.location_es_list.list && this.location_es_list.list.data && this.location_es_list.list.data.length > 0) {
          this.startMapHistory();
        }
      }
    },
    total_data() {
      // this.pagination();
      if (isNaN(this.total_data)) {
        this.total_data = 0
      }
    },
    compare_target_detail() {
      // if (this.compare_target_detail != undefined && this.compare_target_detail.target != undefined) {
      if (this.compare_target_detail != undefined && this.compare_target_detail.first_target != undefined && this.compare_target_detail.second_target != undefined) {
        if (this.getProvider(this.compare_target_detail.first_target_msisdn) == this.getProvider(this.compare_target_detail.second_target_msisdn)) {
          this.orderBy = [
            {
              label: 'All Data',
              value: ''
            },
            {
              label: 'Same Area Code',
              value: 'area_code'
            },
            {
              label: 'Same Cell',
              value: 'cell'
            },
          ]
        } else {
          this.orderBy = [
            {
              label: 'All Data',
              value: ''
            },
          ]
        }

        this.setData(this.compare_target_detail)
      }
    },
    // route(newData, oldData) {
    //   this.setEmptyMap();
    //   // this.mapHistoryLoaded = false;
    //   if (newData.name === 'pageCompareTargetDetail') {
    //     if (oldData !== 'pageCompareTargetDetail') {
    //       this.optFilterTarget = []
    //       this.filterTarget = ''
    //       this.paramMsisdn = []
    //       this.items_per_page = 10000
    //       this.page = 0
    //       this.keyword = ''
    //       this.$store.commit('compareTarget/setMonitoringList', {})
    //       this.$store.commit('compareTarget/setStatusMonitoringList', { cancel: null, code: null, status: 'loading' })
    //     }
    //   }
    // },
    currentClickMarker(newData) {
      // console.log({newData})
      if (!newData) {
        console.log('watch currentClickMarker null value')
        return
      }
      const google = this.google;
      const map = this.$refs.mapRefTargetLoc
      const infoWindow = this.gInfoWIndow;
      // console.log('watch currentClickMarker')
      google.maps.event.trigger(newData, 'click');

      infoWindow.close()
      infoWindow.setContent(newData.desc);
      infoWindow.open(map, newData);
      // console.log('currentClickMarker null')
      // this.currentClickMarker=null
    },
  },
  created() {
    EventEmit.$on('startMapTargetLocations', async () => {
      // console.log("startMapTargetLocations")
      if (this.status_location_es_list !== 'loading') {
        this.openTab();
      }
    });
  },
}
</script>